<template>
  <div class="index">
    <ul class="menu">
      <li @click="openMenu('account')">我的资料</li>
      <li @click="openMenu('accountTruthName')">实名认证</li>
      <li @click="openMenu('accountAddress')">我的地址</li>
      <li @click="openMenu('accountOrder')">我的订单</li>
      <li @click="openMenu('accountCart')">我的购物车</li>
      <li @click="openMenu('accountTuijian')">我的推荐</li>
    </ul>
    <div class="main">
      <div class="title">基本资料</div>
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="90px"
        label-position="left"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="4">
            <el-avatar
              :size="80"
              :src="ruleForm.avatar"
              style="display: block; margin: 15px auto"
            ></el-avatar>
            <el-button
              size="medium"
              @click="changeEmail"
              style="display: block; margin: 15px auto"
              >修改</el-button
            >
          </el-col>
          <el-col :span="20">
            <el-row>
              <el-col :span="12">
                <el-form-item label="账户ID" prop="realName">
                  <el-input
                    v-model="ruleForm.userEncryptionId"
                    readonly=""
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="昵称" prop="nickname">
                  <el-input v-model="ruleForm.nickname" readonly="">
                    <el-button slot="append" type="primary" @click="changeEmail"
                      >修改</el-button
                    >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="代理级别" prop="level">
                  <el-input v-model="viplevelName" readonly="">
                    <el-button slot="append" @click="uploadLevel"
                      >升级</el-button
                    >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="微信号" prop="wechat">
                  <el-input v-model="ruleForm.wechat" readonly=""
                    ><el-button slot="append" @click="changeEmail"
                      >修改</el-button
                    ></el-input
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="账户余额" prop="nowMoney">
                  <el-input v-model="ruleForm.nowMoney" readonly="">
                    <!-- <el-button slot="append">充值</el-button> -->
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="QQ号" prop="qq">
                  <el-input v-model="ruleForm.qq"
                    ><el-button slot="append" @click="changeEmail"
                      >修改</el-button
                    ></el-input
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="奖励金余额" prop="brokeragePrice">
                  <el-input v-model="ruleForm.brokeragePrice" readonly="">
                    <el-button
                      slot="append"
                      @click="applyGetCarsh(ruleForm.brokeragePrice)"
                      >申请提现</el-button
                    >
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="电子邮箱" prop="email">
                  <el-input v-model="ruleForm.email" readonly=""
                    ><el-button
                      slot="append"
                      @click="changeEmail"
                      type="primary"
                      >修改</el-button
                    ></el-input
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="保证金" prop="deposit">
                  <el-input v-model="ruleForm.deposit" readonly="">
                    <el-button slot="append" @click="outAgent"
                      >退出代理</el-button
                    >
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="手机号码" prop="phone">
                  <el-input v-model="oldPhone" readonly="">
                    <el-button
                      slot="append"
                      style="margin-left: 15px"
                      @click="changePhone"
                      type="primary"
                      >{{ ruleForm.phone ? "变更绑定" : "绑定" }}</el-button
                    ></el-input
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-col :span="24">
              <el-form-item
                :label="`电商地址`"
                prop="realName"
                v-for="(i, index) in userStoreList"
                :key="index"
              >
                <el-input v-model="i.targetUrl" readonly=""></el-input>
                <el-button
                  style="margin-left: 15px"
                  v-if="!i.judgeStatus"
                  type="primary"
                  >已提交待审核</el-button
                >
                <el-button
                  style="margin-left: 15px"
                  v-if="i.judgeStatus == 1"
                  type="primary"
                  >审核通过</el-button
                >
                <el-button
                  style="margin-left: 15px"
                  v-if="i.judgeStatus == 2"
                  type="primary"
                  >已结束待重新申请</el-button
                >
              </el-form-item>
              <el-form-item
                :label="`电商地址`"
                prop="realName"
                v-for="i in 5 - userStoreList.length"
                :key="i"
              >
                <el-input readonly=""></el-input>
                <el-button
                  style="margin-left: 15px"
                  @click="apply"
                  type="primary"
                  >促销活动申请</el-button
                >
              </el-form-item>
            </el-col>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-dialog
      title="促销活动申请"
      :visible.sync="payFormVisible"
      :close-on-click-modal="false"
      @close="payFormVisible = false"
      custom-class="dialog-minwidth"
      :modal-append-to-body="false"
      width="600px"
    >
      <el-form
        :model="formData"
        label-width="120px"
        :rules="formRules"
        ref="formData"
        class="payFormXg"
      >
        <el-form-item label="联系电话" prop="phone">
          <el-input
            class="ratioWidth"
            v-model.trim="formData.phone"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="电商地址" prop="targetUrl">
          <el-input
            class="ratioWidth"
            v-model.trim="formData.targetUrl"
            placeholder="请输入电商地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="保底销量" prop="num">
          <el-input
            class="ratioWidth"
            v-model.trim="formData.num"
            placeholder="请输入保底销量(低于1000箱，不用申请)"
          ></el-input>
        </el-form-item>
        <el-form-item label="促销开始日期" prop="startDate">
          <el-date-picker
            v-model="formData.startDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择促销开始日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="促销结束日期" prop="endDate">
          <el-date-picker
            v-model="formData.endDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择促销截止日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="" prop="description">
          <el-input
            class="ratioWidth"
            type="textarea"
            style="width: 330px"
            v-model.trim="formData.description"
            placeholder="请输入需要公司配合活动的详细说明"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="cancel" @click="cancel">取 消</el-button>
        <el-button
          type="primary"
          class="save"
          :loading="isSaveLoading"
          @click="submit"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 升级 -->
    <el-dialog
      title="代理级别升级"
      :visible.sync="isLevel"
      :close-on-click-modal="false"
      @close="isLevel = false"
      custom-class="dialog-minwidth"
      :modal-append-to-body="false"
      width="800px"
    >
      <el-row class="levelHeader">
        <el-col :span="4">级别</el-col>
        <el-col :span="4">首次投入</el-col>
        <el-col :span="4">保证金</el-col>
        <el-col :span="4">享受单价</el-col>
        <el-col :span="4">相当于首次提货</el-col>
        <el-col :span="4">升级级别</el-col>
      </el-row>
      <el-row
        v-for="item in levelList"
        :key="item.id"
        class="levelHeader levelItem"
      >
        <el-col :span="4">{{ item.name }}</el-col>
        <el-col :span="4">{{ item.firstInvestment }}</el-col>
        <el-col :span="4">{{ item.deposit }}</el-col>
        <el-col :span="4">{{ getSingleprice(item.id) }}</el-col>
        <el-col :span="4">{{ getFirst(item.id) }}</el-col>
        <el-col :span="4"
          ><el-link @click="upgredeLevel(item)">{{
            item.isUpgrade ? "升级" : "不可升级"
          }}</el-link></el-col
        >
      </el-row>
      <div style="height: 20px"></div>
    </el-dialog>
    <el-dialog
      title="升级代理等级"
      :visible.sync="showPay"
      :close-on-click-modal="false"
      @close="showPay = false"
      custom-class="dialog-minwidth"
      :modal-append-to-body="false"
      width="850px"
    >
      <div class="uploadVip">
        <div class="outTips">您当前的用户等级为{{ viplevelName }}</div>
        <div class="outTips" v-if="paytypes == 'bankCard'">
          选择升级为{{ currentLevelItems.name }}，需要支付<span>{{
            currentLevelItems.upgradePrice
          }}</span
          >元，
        </div>
        <div class="outTips" v-if="paytypes == 'weixin'">
          选择升级为{{ currentLevelItems.name }}，需要支付<span>{{
            currentLevelItems.upgradePrice
          }}</span
          >元 + 手续费 <span>{{ currentLevelItems.servicePrice }}</span
          >元
        </div>

        <h3 class="outTips">请选择支付方式</h3>
        <el-row class="btnbox">
          <el-col
            :span="12"
            :class="paytypes == 'bankCard' ? 'actived' : ''"
            @click.native="paytypesbankCard"
            >银行卡支付</el-col
          >
          <el-col
            :span="12"
            :class="paytypes == 'weixin' ? 'actived' : ''"
            @click.native="paytypesweixin"
            >微信支付</el-col
          >
        </el-row>
        <el-row class="bankPay" v-if="paytypes == 'bankCard'">
          <el-col :span="10">
            <img
              :src="require('@/assets/images/bankcard.jpg')"
              alt=""
              class="payImage"
            />
            <el-button
              :disabled='upgreadeLevelStatus'
              :type="upgreadeLevelStatus?'info':'danger'"
              @click="niticeCheck"
              size="medium"
              style="margin: 15px auto; display: block"
              >{{upgreadeLevelStatus?'已提交，待审核':'完成付款后，请提交审核'}}</el-button
            >
            <el-button type="primary" v-if="!upgreadeLevelStatus" @click="cancelUpOrder" style="width:100%;margin:20px auto 20px;">取消支付</el-button>
          </el-col>
          <el-col :span="13" :offset="1">
            <div class="tipsTitle">温馨提示：</div>
            <p>1、扫码后请在转账页面填写金额，并备注手机号或者用户账号。</p>
            <p>
              2、扫码转账完成后，请一定要提交客服审核，如果忘了提交，可再次打开升级页面选择银行卡支付来提交申请。
            </p>
            <p>
              3、部分银行转账是24小时到账，具体请咨询各银行网点。转账时可随机增减几分钱方便客服查找。
            </p>
            <p>
              4、资金到账后，客服一般会在两个小时内完成审核。节假日顺延。若有意外延迟，将在网站公告预计完成时间，请留意公告。
            </p>
            <p>
              5、部分银行银联人到人付款限额过低，可分两次支付，总额相加正确即可。详询客服
            </p>
            <p>
              6、部分银行未开通银联人到人付款的，可自行转账付款，和二维码收款一样。收款人：李宽。开户银行卡：招商银行。卡号：6214855122175595
            </p>
            
          </el-col>
        </el-row>
        <el-row class="weixinPays" v-if="paytypes == 'weixin'">
          <el-col :span="10">
            <div class="weixinPaysBox" v-if="!upgreadeLevelStatus">
              <img :src="weixinPaycode" alt="" />
            </div>
            <div v-else style="height: 400px;line-height:400px;text-align:center;">
              已支付，等待审核
            </div>
          </el-col>
          <el-col :span="13" :offset="1">
            <div class="tipsTitle">温馨提示：</div>
            <p>
              1、微信支付升级需承担腾讯收取的手续费，交易金额的千分之六。银行卡转账升级免收手续费。
            </p>
            <p>2、微信支付无需审批，支付成功后会自动升级级别。</p>
            <p>3、取消支付页面后，请5分钟后再次重试。</p>
            <p>4、微信支付成功后请刷新页面。</p>
            <el-button type="primary" v-if="!upgreadeLevelStatus" @click="cancelUpOrder" style="width:100%;margin:40px auto 20px;">取消支付</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <!-- 换绑手机号 -->
    <el-dialog
      title="换绑手机号"
      :visible.sync="showPhone"
      :close-on-click-modal="false"
      @close="showPhone = false"
      custom-class="dialog-minwidth"
      :modal-append-to-body="false"
      width="500px"
    >
      <el-form
        :model="phoneData"
        ref="phoneForm"
        label-width="100px"
        label-position="left"
        class="demo-ruleForm"
        :rules="phoneRule"
      >
        <el-form-item label="当前手机号：">
          <el-input
            v-model="phoneData.oldphone"
            placeholder="请输入原手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="新手机号" prop="newPhone">
          <el-input v-model="phoneData.newPhone"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="msgCode">
          <el-input v-model="phoneData.msgCode">
            <el-button slot="append" :disabled="disableds" @click="getMsg">{{
              disableds ? `${secendNo}后重新发送` : phoneMsgTip
            }}</el-button>
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            style="width: 80%; margin-bottom: 30px"
            :loading="isSaveLoading"
            @click="submitChange"
            type="primary"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 换绑邮箱 -->
    <el-dialog
      title="修改用户信息"
      :visible.sync="showEmail"
      :close-on-click-modal="false"
      @close="showPhone = false"
      custom-class="dialog-minwidth"
      :modal-append-to-body="false"
      width="500px"
    >
      <el-form
        :model="userData"
        ref="userinfoForm"
        label-width="100px"
        label-position="left"
        class="demo-ruleForm"
        :rules="userRule"
      >
        <el-form-item label="用户昵称：" prop="nickname">
          <el-input v-model="userData.nickname"></el-input>
        </el-form-item>
        <el-form-item label="用户头像：" prop="avatar">
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :http-request="aliyunUploadBank"
          >
            <img v-if="userData.avatar" :src="userData.avatar" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="邮箱地址：">
          <el-input v-model="userData.email"></el-input>
        </el-form-item>
        <el-form-item label="微信号：" prop="wechat">
          <el-input v-model="userData.wechat"></el-input>
        </el-form-item>
        <el-form-item label="QQ号：" prop="qq">
          <el-input v-model="userData.qq"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            style="width: 80%; margin-bottom: 30px"
            type="primary"
            @click="submitUserInfo"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 退出代理 -->
    <el-dialog
      title="退出代理"
      :visible.sync="outAgents"
      :close-on-click-modal="false"
      @close="showPhone = false"
      custom-class="dialog-minwidth"
      :modal-append-to-body="false"
      width="600px"
    >
      <div class="agentConfig">
        <div>您当前的用户等级为：{{ changeviplevelName(agentConfig.level )}}</div>
        <!-- <div>首次升级时间：{{ agentConfig.firstUpgradeDate }}</div> -->
        <div>保证金：{{ agentConfig.deposit }}</div>
        <div>余额：{{ agentConfig.prePrice }}</div>
         <!-- <div>奖励金余额：{{ agentConfig.preBrokeragePrice }}</div> -->
       <!-- <div>总金额：{{ agentConfig.totalPrice }}</div> -->
      </div>
      <div slot="footer">
        <el-button type="primary" @click="applyOutAgent">确定</el-button>
        <el-button type="default" @click="cancelOut">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="奖励金提现申请"
      :visible.sync="isApplyCarsh"
      :close-on-click-modal="false"
      @close="isApplyCarsh = false"
      custom-class="dialog-minwidth"
      :modal-append-to-body="false"
      width="600px"
    >
      <el-form :model="cashData" label-width="120px" class="payFormXg">
        <el-form-item label="奖励金总额">
          <el-input
            class="ratioWidth"
            v-model.trim="ruleForm.brokeragePrice"
            readonly=""
          ></el-input>
        </el-form-item>
        <el-form-item label="提现金额" prop="price">
          <el-input
            class="ratioWidth"
            v-model.trim="cashData.price"
            placeholder="请输入提现金额"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="applyCash">确定</el-button>
        <el-button type="default" @click="cancelCash">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { aoiGetUserinfo } from "@/api/login";

export default {
  name: "basePAge",
  data() {
    return {
      isApplyCarsh: false,
      cashData: { price: "" },
      outAgents: false,
      agentConfig: {},
      headers: { "Authori-zation": localStorage.getItem("access_token") },
      info: {},
      ruleForm: { avatar: "" },
      isSaveLoading: false,
      payFormVisible: false,
      formData: {
        description: "",
        endDate: "",
        num: "",
        phone: "",
        startDate: "",
        targetUrl: "",
      },
      formRules: {
        phone: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
        targetUrl: [
          { required: true, message: "请输入电商地址", trigger: "blur" },
        ],
        num: [{ required: true, message: "请输入保底销量", trigger: "blur" }],
        startDate: [
          { required: true, message: "请选择促销开始日期", trigger: "change" },
        ],
        endDate: [
          { required: true, message: "请选择促销结束日期", trigger: "change" },
        ],
      },
      isLevel: false,
      levelList: [],
      showPay: false,
      payUrl: "",

      showPhone: false,
      phoneMsgTip: "发送验证码",
      disableds: false,
      secendNo: 60,
      timer: null,
      phoneData: {
        oldphone: "",
        msgCode: "",
        newPhone: "",
      },
      phoneRule: {
        msgCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        newPhone: [
          { required: true, message: "请输入新手机号", trigger: "blur" },
        ],
      },

      showEmail: false,
      newEmail: "",
      userData: {
        avatar: "",
        email: "",
        nickname: "",
        qq: "",
        wechat: "",
      },
      userRule: {
        nickname: [
          { required: true, message: "请输入用户昵称", trigger: "blur" },
        ],
        avatar: [
          { required: true, message: "请上传用户头像", trigger: "change" },
        ],
      },
      userStoreList: [],
      currentLevelItems: {},
      paytypes: "bankCard",
      weixinPaycode: "",
      upgreadOrder: {},
      isDisabled: false,
      upgreadeLevelStatus: false
    };
  },
  mounted() {
    this.getUserInfo();
  },
  computed: {
    oldPhone() {
      if (this.ruleForm.phone) {
        return (
          this.ruleForm.phone.substring(0, 3) +
          "****" +
          this.ruleForm.phone.substring(7, 11)
        );
      } else {
        return "--";
      }
    },
    viplevelName() {
      if (this.ruleForm.level == 2) {
        return "达人";
      } else if (this.ruleForm.level == 3) {
        return "大户";
      } else if (this.ruleForm.level == 4) {
        return "大咖";
      } else if (this.ruleForm.level == 5) {
        return "大王(女王)";
      } else {
        return "游客";
      }
    },
  },
  methods: {
    changeviplevelName(str) {
      if (str == 2) {
        return "达人";
      } else if (str == 3) {
        return "大户";
      } else if (str == 4) {
        return "大咖";
      } else if (str == 5) {
        return "大王(女王)";
      } else {
        return "游客";
      }
    },
    //奖励金提现
    applyGetCarsh(money) {
      if (money < 1000) {
        this.$message.warning("奖励金余额必须大于1000元才可提现");
      } else {
        this.isApplyCarsh = true;
      }
    },
    applyCash() {
      if (this.cashData.price < 1000) {
        this.$message.warning("提现金额至少1000元起");
        return false;
      }
      this.https
        .post("/api/front/brokerage/withdrawalApply", {
          price: this.cashData.price,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success("提现申请成功！");
            this.isApplyCarsh = false;
            this.getUserInfo();
          } else {
            this.$message.warning(res.data.message);
          }
        });
    },
    cancelCash() {
      this.isApplyCarsh = false;
      this.cashData.price = "";
    },
    //退出dali
    outAgent() {
      this.https.get("/api/front/user/quitAgentResult").then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          if (!res.data.data) {
            this.getAgentStatus();
          } else {
            if (res.data.data.status == 0) {
              this.$message.warning("您的退出代理申请正在审核中");
            } else if (res.data.data.status == 1) {
              this.$message.warning("您的退出代理申请已通过审核");
            } else if (res.data.data.status == -1) {
              // this.$message.warning("您的退出代理申请已驳回");
              this.$confirm("您的退出代理申请已驳回，是否再次申请?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.getAgentStatus();
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消",
                  });
                });
            }
          }
        } else {
          this.$message.warning(res.data.message);
        }
      });
    },
    //获取当前用户代理状态
    getAgentStatus() {
      this.$confirm(
        "是否确认退出代理，退出代理后平台的一切权利全部作废,请谨慎选择！",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.getAgentInfo();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //确认申请退代理
    applyOutAgent() {
      this.https.post("/api/front/user/quitAgent").then((res) => {
        if (res.data.code == 200) {
          this.$message.success("退出代理申请成功！");
          this.outAgents = false;
          this.getUserInfo();
        } else {
          this.$message.warning(res.data.message);
        }
      });
    },
    //cancelOut
    cancelOut() {
      this.agentConfig = {};
      this.outAgents = false;
    },
    //获取退出代理时信息
    getAgentInfo() {
      this.https.get("/api/front/user/quitAgentConfig").then((res) => {
        if (res.data.code == 200) {
          this.agentConfig = res.data.data;
          this.outAgents = true;
        } else {
          this.$message.warning(res.data.message);
        }
      });
    },
    getviplevelName(val) {
      if (val == 2) {
        return "达人";
      } else if (val == 3) {
        return "大户";
      } else if (val == 4) {
        return "大咖";
      } else if (val == 5) {
        return "大王(女王)";
      } else {
        return "游客";
      }
    },
    aliyunUploadBank(params) {
      let ossData = new FormData();
      ossData.append("multipart", params.file);
      ossData.append("model", "user");
      ossData.append("pid", 7);
      this.https.post("/api/front/user/upload/image", ossData).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.userData.avatar = res.data.data.url;
        }
      });
    },
    //换手机号
    changePhone() {
      this.showPhone = true;
    },
    getMsg() {
      if (!this.phoneData.oldphone) {
        this.$message.warning("请输入原手机号");
        return false;
      }
      if (!this.phoneData.oldphone == this.ruleForm.phone) {
        this.$message.warning("原手机号输入错误");
        return false;
      }
      if (!this.phoneData.newPhone) {
        this.$message.warning("请输入手机号");
        return false;
      }
      this.https
        .post("/api/front/sendCode?phone=" + this.phoneData.newPhone)
        .then(() => {
          this.$message({
            message: "发送成功",
            type: "success",
            center: true,
            offset: 300,
            duration: 2000,
          });
          if (!this.timer) {
            this.secendNo = 60;
            this.disableds = true;
            this.timer = setInterval(() => {
              if (this.secendNo > 0 && this.secendNo <= 60) {
                this.secendNo--;
              } else {
                this.disableds = false;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        });
    },
    //提交手机号
    submitChange() {
      this.$refs["phoneForm"].validate((valid) => {
        if (valid) {
          this.https
            .post("/api/front/update/binding", {
              oldPhone: this.phoneData.oldphone,
              captcha: this.phoneData.msgCode,
              phone: this.phoneData.newPhone,
            })
            .then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                  center: true,
                  offset: 300,
                  duration: 2000,
                });
                this.showPhone = false;
                this.getUserInfo();
              } else {
                this.$message({
                  message: res.data.message,
                  type: "warning",
                  center: true,
                  offset: 300,
                  duration: 2000,
                });
              }
            });
        }
      });
    },
    //修改基本信息
    changeEmail() {
      this.showEmail = true;
      this.userData = {
        avatar: this.ruleForm.avatar,
        email: this.ruleForm.email,
        nickname: this.ruleForm.nickname,
        qq: this.ruleForm.qq,
        wechat: this.ruleForm.wechat,
      };
    },
    //
    submitUserInfo() {
      this.$refs["userinfoForm"].validate((valid) => {
        if (valid) {
          this.isSaveLoading = true;
          this.https.post("/api/front/user/edit", this.userData).then((res) => {
            this.isSaveLoading = false;
            if (res.data.code == 200) {
              this.$message.success("保存成功");
              this.showEmail = false;
              this.getUserInfo();
            } else {
              this.$message.warning(res.data.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //获取用户信息
    getUserInfo() {
      aoiGetUserinfo().then((res) => {
        if (res.code == 200) {
          console.log(res);
          this.ruleForm = res.data;
          this.userStoreList = res.data.userStoreList;
        }
      });
    },
    openMenu(index) {
      this.$router.push({ name: index });
      localStorage.setItem("accountIndex", "account");
    },
    apply() {
      this.payFormVisible = true;
      this.$nextTick(() => {
        this.$refs["formData"].resetFields();
        this.formData.phone = this.ruleForm.phone;
      });
    },
    cancel() {
      this.payFormVisible = false;
      this.$refs["formData"].resetFields();
    },
    submit() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          this.isSaveLoading = true;
          let params = this.formData;
          params.uid = this.ruleForm.userEncryptionId;
          this.https.post("/api/front/userStore/add", params).then((res) => {
            console.log(res);
            this.isSaveLoading = false;
            if (res.data.code == 200) {
              this.payFormVisible = false;
              this.$message.success("保存成功");
              this.getUserInfo();
            } else {
              this.$message.warning(res.data.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //获取可升级级别列表按钮
    uploadLevel() {
      this.https
        .post("/api/front/upgrade/index", { payType: "weixin" })
        .then((res) => {
          if (res.data.code == 200) {
            this.isLevel = true;
            this.levelList = res.data.data;
          } else {
            this.getUploadOrder();
          }
        });
    },
    getSingleprice(index) {
      if (index == 1) {
        return " - - ";
      } else if (index == 2) {
        return "90元/包";
      } else if (index == 3) {
        return "80元/包";
      } else if (index == 4) {
        return "70元/包";
      } else if (index == 5) {
        return "60元/包";
      }
    },
    getFirst(index) {
      if (index == 1) {
        return " - - ";
      } else if (index == 2) {
        return "5箱";
      } else if (index == 3) {
        return "25箱";
      } else if (index == 4) {
        return "100箱";
      } else if (index == 5) {
        return "250箱";
      }
    },
    //点击对应的级别 弹出升级弹窗
    async upgredeLevel(item) {
      let that = this;
      if (item.isUpgrade) {
        const results = await that.https.post("/api/front/upgrade/one", {
          levelId: item.id,
          payType: that.paytypes == "bankCard" ? "offline" : "weixin",
        });
        console.log(results);
        if (results.data.code == 200) {
          that.currentLevelItems = results.data.data;
          that.showPay = true;
          let params = {
            levelId: that.currentLevelItems.id,
            payType: that.paytypes == "bankCard" ? "offline" : "weixin",
            price: that.currentLevelItems.totalMoney,
            servicePrice:
              that.paytypes == "bankCard"
                ? 0
                : that.currentLevelItems.servicePrice,
            upgradePrice: that.currentLevelItems.upgradePrice,
          };
          const uploadResult = await that.https.post(
            "/api/front/upgrade/upgrade",
            params
          );
          console.log(uploadResult);
          if (uploadResult.data.code == 200) {
            that.upgreadOrder = uploadResult.data.data;
            if (that.paytypes == "weixin") {
              that.weixinPaycode = uploadResult.data.data.qrCodeImageBase64;
            }
          } else {
            that.$message.warning(uploadResult.data.message);
          }
        }
      }
    },
    paytypesbankCard() {
      if(this.upgreadeLevelStatus){
        return false
      }
      let that = this;
      that.paytypes = "bankCard";
      that.https
        .get("/api/front/upgrade/cancel?orderNo=" + that.upgreadOrder.orderNo)
        .then((res) => {
          if (res.data.code == 200) {
            that.upgredeLevel(that.currentLevelItems);
          } else {
            console.log(res.data.message);
          }
        });
    },
    paytypesweixin() {
      if(this.upgreadeLevelStatus){
        return false
      }
      let that = this;
      that.paytypes = "weixin";
      that.https
        .get("/api/front/upgrade/cancel?orderNo=" + that.upgreadOrder.orderNo)
        .then((res) => {
          if (res.data.code == 200) {
            that.upgredeLevel(that.currentLevelItems);
          } else {
            console.log(res.data.message);
          }
        });
    },
    cancelUpOrder(){
      let that = this;
      that.https
        .get("/api/front/upgrade/cancel?orderNo=" + that.upgreadOrder.orderNo)
        .then((res) => {
          if (res.data.code == 200) {
            that.$message.success('已取消升级')
            that.showPay = false
          } else {
            console.log(res.data.message);
          }
        });
    },
    niticeCheck() {
      this.$confirm(
        "请完成银行转账付款后，再提交申请审核！确定已完成转账了吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.https
            .post(
              "/api/front/upgrade/offlineStatus?orderId=" +
                this.upgreadOrder.orderNo
            )
            .then((res) => {
              if (res.data.code == 200) {
                this.$message.success("申请审核提交成功");
                this.isLevel = false;
                this.showPay = false;
                this.upgreadeLevelStatus = true
              } else {
                this.$message.warning(res.data.message);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消申请",
          });
        });
    },
    getUploadOrder() {
      this.https.get("/api/front/upgrade/upgradeOrder").then((res) => {
        if (res.data.code == 200) {
          this.showPay = true;
          if(res.data.data.customerStatus == 1){
            this.upgreadeLevelStatus = true
          }
          this.currentLevelItems = {
            deposit: res.data.data.deposit,
            firstInvestment: res.data.data.firstInvestment,
            firstPickupNum: res.data.data.firstPickupNum,
            id: res.data.data.levelId,
            isUpgrade: true,
            name: res.data.data.name,
            orderNo: res.data.data.orderNo,
            payType: res.data.data.payType,
            totalMoney: res.data.data.price,
            servicePrice: res.data.data.servicePrice,
            unitPrice: res.data.data.unitPrice,
            upgradePrice: res.data.data.upgradePrice,
          };
          this.upgreadOrder.orderNo = res.data.data.orderNo;
          if (res.data.data.payType == "weixin") {
            this.paytypes = "weixin";
            this.weixinPaycode = res.data.data.qrCodeImageBase64;
          } else {
            this.paytypes = "bankCard";
            this.weixinPaycode = "";
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.index {
  width: 1200px;
  margin: 0 auto;
  background-color: #f5f6f7;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  text-align: left;
  .menu {
    width: 200px;
    background-color: #fff;
    li {
      line-height: 60px;
      border-bottom: 1px solid #ccc;
      font-size: 16px;
      text-align: center;
    }
  }
  .main {
    width: 980px;
    background-color: #fff;
    padding: 15px 20px;
    text-align: left;
    .title {
      line-height: 50px;
      text-align: left;
      font-size: 16px;
      border-bottom: 1px dashed blue;
      margin-bottom: 20px;
      .el-col {
        cursor: pointer;
      }
    }
  }
}
::v-deep.el-form-item__content {
  margin-left: 80px !important;
}
.el-input {
  width: 280px;
}
.levelHeader {
  line-height: 50px;
  .el-col {
    border: 1px solid #f5f6f7;
    text-align: center;
  }
}
.levelItem {
  .el-col {
    border-top: 1px solid transparent;
  }
}

::v-deep.el-upload {
  width: 158px;
  height: 158px;
  line-height: 158px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #0000ff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 158px;
  height: 158px;
  line-height: 158px;
  text-align: center;
}
.avatar {
  width: 158px;
  height: 158px;
  display: block;
}
.uploadVip {
  padding: 15px 15px 20px;
  .outTips {
    line-height: 30px;
    font-size: 16px;
    color: #666;
    span {
      font-size: 24px;
      margin: 0 8px;
      color: red;
    }
  }
  h3 {
    font-size: 18px;
    line-height: 46px;
  }
  .btnbox {
    line-height: 46px;
    .el-col {
      border: 1px solid #eee;
      text-align: center;
      background-color: #fff;
    }
    .actived {
      border: 1px solid orangered;
    }
    .el-button {
      width: 120px;
      margin-right: 15px;
    }
  }
  .bankPay {
    padding: 15px;
    border: 1px solid #eee;
    border-radius: 5px;
    .payImage {
      width: 300px;
    }
    .tipsTitle {
      line-height: 40px;
      font-size: 18px;
      color: red;
    }
    p {
      line-height: 26px;
      font-size: 16px;
      color: #999;
      margin-bottom: 10px;
    }
  }
  .weixinPays {
    padding: 15px;
    border: 1px solid #eee;
    border-radius: 5px;
    .weixinPaysBox {
      width: 300px;
      height: 500px;
      background: url("./../../assets/images/weixinPayBg.png") center center
        no-repeat;
      background-size: 150% 100%;
      padding: 130px 56px;
      img {
        display: block;
        width: 184px;
        height: 184px;
        background: red;
      }
    }
    .tipsTitle {
      padding-top: 50px;
      line-height: 40px;
      font-size: 18px;
      color: red;
    }
    p {
      line-height: 26px;
      font-size: 16px;
      color: #999;
      margin-bottom: 10px;
    }
  }
}
.agentConfig {
  div {
    line-height: 36px;
  }
}
::v-deep.el-input-group__append {
  color: #fff;
  background-color: #0000ff;
  border-color: #0000ff;
}
</style>