import {get, post} from './axios'
//手机号登陆
export const apiPhoneLogin = p => post('/api/front/v2/login/mobile',p)
//  获取验证码api/front/sendCode
export const apiGetMsgCode = p => post('/api/front/sendCode?phone=' + p.phone)
//账号密码登录
export const apiAccountLogin = p => post('/api/front/login',p)

//获取用户个人信息
export const aoiGetUserinfo = p => get('/api/front/user/info',p)

